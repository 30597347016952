import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "entries", "pagination" ]

  // **********************************
  // This component will be used for infinite scroll in the future
  // **********************************
  scroll() {
    const url = this.paginationTarget.querySelector("a[rel='next']").href
    const body = document.body
    const html = document.documentElement
    const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

    if (window.pageYOffset >= height - window.innerHeight) {
      this.loadMore(url)
    }
  }

  loadMore(url) {
    // TODO: Do it later
  }
}
